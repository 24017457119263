@import 'src/styles/variables/_colors.scss';
@import 'src/styles/variables/_sizes.scss';

.inspection-types-details-page {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 42px;
    font-weight: 600;
    font-family: 'MaisonNeue-DemiBold';
  }

  .header-info {
    display: flex;
    column-gap: 24px;
    margin-bottom: 24px;

    p {
      font-family: 'MaisonNeue-Medium';
      font-size: 14px;
      font-weight: 500;
      color: $qm-color-text-tertiary;
      margin: 0;
    }
  }

  .row {
    display: flex;
    flex-direction: row;

    margin-bottom: $qm-margin-l;

    &.inspection-types-header {
      justify-content: space-between;
    }

    .details {
      width: 50%;

      h1 {
        margin-bottom: $qm-margin-s;
      }
    }
  }

  .content {
    h4 {
      margin-bottom: $qm-margin-m;
    }

    .table {
      display: table;
      width: 100%;

      .table-row {
        display: table-row;

        &.table-header {
          .table-cell {
            padding-bottom: 4px;
            font-size: 14px;
            color: $qm-color-text-tertiary;
          }
        }
      }

      .table-cell {
        display: table-cell;
        padding: 8px 0;
        line-height: 22px;

        &.pill-cell {
          display: flex;
          flex-wrap: wrap;

          .pill {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .usage-decisions-grid {
    display: grid;
    grid-template-columns: auto auto auto;

    h5 {
      grid-column: 1 / span all;
      font-family: 'MaisonNeue-Medium';
      font-size: 20px;
      font-weight: 500;
    }

    h5:not(:first-child) {
      margin-top: $qm-margin-l;
    }

    .severe-defect-fail-notify-users {
      margin: 1em 0;
      grid-column: 1 / span all;

      .users-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $qm-padding;
        margin-top: 0.5em;

        .user {
          display: flex;
          gap: $qm-padding-ms;
          align-items: center;
        }
      }
    }

    .usage-decisions-table {
      display: contents;

      .table-row {
        display: contents;
      }
    }
  }
}
